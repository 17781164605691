import { Button } from '@mui/material'
import { Stack } from '@mui/system'
import React, { ForwardedRef, MutableRefObject, forwardRef } from 'react'

interface Props {
    cancelLabel: string
    okLabel: string
    cancelClick(): void
    okClick(): void
    disabledOk?: boolean
}

interface ButtonsRef {
    cancelRef: MutableRefObject<HTMLButtonElement | null> | null
    okRef: MutableRefObject<HTMLButtonElement | null> | null
}

export const ActionsFooter = forwardRef<ButtonsRef, Props>(
    ({ cancelLabel, okLabel, okClick, cancelClick, disabledOk }, ref) => {
        return (
            <Stack
                sx={{ width: '100%', mt: '20px' }}
                flexDirection={{ xs: 'column-reverse', md: 'row' }}
                justifyContent="space-between"
            >
                {cancelLabel && (
                    <Button
                        variant="contained"
                        sx={{
                            textTransform: 'none',
                            width: { xs: '100%', md: '280px' },
                            fontSize: '18px',
                            p: '15px',
                            mt: { xs: '15px', md: '0' },
                        }}
                        color="secondary"
                        onClick={cancelClick}
                        //@ts-ignore
                        ref={ref?.current?.cancelRef}
                    >
                        {cancelLabel}
                    </Button>
                )}
                {okLabel && (
                    <Button
                        variant="contained"
                        sx={{
                            textTransform: 'none',
                            width: { xs: '100%', md: '280px' },
                            fontSize: '18px',
                            p: '15px',
                        }}
                        color="primary"
                        onClick={okClick}
                        disabled={disabledOk}
                        //@ts-ignore
                        ref={ref?.current?.okRef}
                    >
                        {okLabel}
                    </Button>
                )}
            </Stack>
        )
    }
)

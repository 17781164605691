import { CircularProgress, createTheme, ThemeProvider } from '@mui/material'
import { lazy, Suspense, useEffect } from 'react'
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom'
import { LoadingProvider } from './context/loading_context'
import { SnackProvider } from './context/snack_context'

import { doLogout, isLogged } from './services/auth'
import Layout from './components/Layout'
import { Company } from './pages/Company'

const Login = lazy(() => import('./pages/Login'))

function System() {
    return (
        <Layout>
            <Routes>
                <Route path="company/*" element={<Company />} />
                <Route path="*" element={<Navigate to="/system/company" />} />
            </Routes>
        </Layout>
    )
}

function App() {
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const script = document.createElement('script')
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`
        document.body.appendChild(script)
    }, [])

    useEffect(() => {
        const logged = isLogged()
        if (location.pathname.includes('/system') && !logged) {
            doLogout()
            navigate('/login')
        } else if (logged && location.pathname.includes('/login')) {
            navigate('/system')
        }
    }, [location.pathname])

    const theme = createTheme({
        typography: {
            fontFamily: 'Poppins',
        },
        palette: {
            primary: {
                main: '#3a49e7',
            },
            secondary: {
                main: '#FFFFFF',
            },
            text: {
                primary: '#333333',
            },
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& label.Mui-focused': {
                            color: '#333',
                        },
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: '#333',
                            },
                            '& fieldset': {
                                borderColor: '#AAA',
                            },
                            '&:hover fieldset': {
                                borderColor: '#333',
                            },
                        },
                    },
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        '& label.Mui-focused': {
                            color: '#333',
                        },
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: '#333',
                            },
                            '& fieldset': {
                                borderColor: '#AAA',
                            },
                            '&:hover fieldset': {
                                borderColor: '#333',
                            },
                        },
                    },
                },
            },
            /* MuiCheckbox: {
                styleOverrides: {
                    root: {
                        '&.Mui-checked': {
                            color: '#2e7d32',
                        },
                    },
                },
            }, */
            MuiRadio: {
                styleOverrides: {
                    root: {
                        '&.Mui-checked': {
                            color: '#1976d2',
                        },
                    },
                },
            },
        },
    })

    return (
        <Suspense
            fallback={
                <CircularProgress
                    sx={{
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        position: 'absolute',
                    }}
                />
            }
        >
            <ThemeProvider theme={theme}>
                <LoadingProvider>
                    <SnackProvider>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/system/*" element={<System />} />
                            <Route
                                path="*"
                                element={<Navigate to="/login" />}
                            />
                        </Routes>
                    </SnackProvider>
                </LoadingProvider>
            </ThemeProvider>
        </Suspense>
    )
}

export default App

import { useTheme } from '@emotion/react'
import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import {
    Box,
    FormControl,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    Stack,
    SxProps,
    Typography,
    styled,
    useMediaQuery,
    CircularProgress,
    Tooltip,
    Button,
    Autocomplete,
    TextField,
    ButtonGroup,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useEffect, useState, useRef } from 'react'
import { StringField } from '../StringField'
import { StyledPaper } from '../StyledPaper'
import { TitleHeader } from '../TitleHeader'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import { FilterBox } from '../FilterBox'
import { useLoading } from '../../context/loading_context'
import ReplayIcon from '@mui/icons-material/Replay'

const BORDER_COLOR = '#AAA'

const StyledDataGrid = styled(DataGrid)(({}) => ({
    '& .row': {
        backgroundColor: '#FFFFFF',
        border: 'none',
    },
    '& .subrow': {
        backgroundColor: '#EFEFEF',
        '& span': {
            display: 'none',
        },
    },
    '& .recursive': {
        backgroundColor: '#EFEFEF',
        '& span': {
            display: 'none',
        },
    },
    '& .cell-border': {
        borderColor: BORDER_COLOR,
    },
}))

interface NoRowsDataGridProps {
    loading?: boolean
    sx?: SxProps
}

function NoRowsDataGrid({ loading, sx }: NoRowsDataGridProps) {
    return (
        <Stack
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
                border: '1px solid',
                borderColor: BORDER_COLOR,
                borderRadius: '6px',
                py: '20px',
                mt: '15px',
                ...sx,
            }}
        >
            {loading ? (
                <CircularProgress sx={{ my: '15px' }} />
            ) : (
                <>
                    <ReceiptLongIcon
                        sx={{ transform: 'scale(2)', my: '15px' }}
                    />
                    <Typography fontSize={'18px'}>
                        Ningún resultado encontrado
                    </Typography>
                </>
            )}
        </Stack>
    )
}

interface Filter {
    name: string
    labelField: string
    valueField: string
    values: any[]
    selectedValues: any[]
    autoComplete?: {
        cb: (v: string) => void
    }
    hideSelection?: boolean
}

interface Column {
    name: string
    property: string[]
    customRender?(row: any, value: any): JSX.Element
    parser?(value: any): any
    hideSm?: boolean
    hideXs?: boolean
    flex?: number
}

interface Pagination {
    page: number
    rows: number
    rowsCount?: number
    handleChangePagination(page: number, rows: number): void
}

interface Action {
    name: string
    dropdownActions?: Action[]
    color:
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'success'
        | 'error'
        | 'info'
        | 'warning'
    hide?: boolean
}

interface Props {
    title: string
    filters: Filter[]
    columns: Column[]
    values: any[]
    identifier: string
    selectedValues: (number | string)[]
    subValueField?: string[]
    subValueIndentifier?: string
    isRecursive?: boolean
    pagination?: Pagination
    actions?: Action[]
    search: string
    withoutPaper?: boolean
    withoutSelection?: boolean
    hideFilters?: boolean
    hideSearch?: boolean
    hidePagination?: boolean
    handleChangeSearch(value: string): void
    handleExecuteAction?(action: string): void
    handleChangeSelectedFilter(selectedFilter: {
        name: string
        value: any
        action: 'add' | 'remove'
    }): void
    handleChangeSelectedValues(values: (number | string)[]): void
    handleClickRow?(identifier: any): void
    handleClickDetails?(identifier: any): void
    handleReload?(): void
    sx?: SxProps
}

export function GenericTable({
    title,
    filters,
    columns,
    values,
    identifier,
    selectedValues,
    subValueField,
    subValueIndentifier,
    isRecursive,
    pagination,
    actions,
    withoutPaper,
    withoutSelection,
    hideFilters,
    hideSearch,
    hidePagination,
    search,
    handleChangeSearch,
    handleExecuteAction,
    handleChangeSelectedFilter,
    handleChangeSelectedValues,
    handleClickRow,
    handleClickDetails,
    handleReload,
    sx,
}: Props) {
    const theme: any = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.down('md'))
    const isXs = useMediaQuery(theme.breakpoints.only('xs'))
    const [dropdownActionsAnchorEl, setDropdownActionsAnchorEl] =
        useState<HTMLElement | null>(null)
    const timeoutRef = useRef<NodeJS.Timeout>()
    const [intervalReload, setIntervalReload] = useState(0)
    const [currentIntervalReload, setCurrentIntervalReload] = useState(0)
    const [intervalReloadAnchorEl, setIntervalReloadAnchorEl] =
        useState<HTMLElement | null>(null)
    const selectedAction = useRef<Action | null>(null)

    const [showSubValues, setShowSubValues] = useState<
        { id: any; show: boolean }[]
    >(values.map((value) => ({ id: value[identifier], show: false })))

    const { isLoading } = useLoading()

    const valuesSum = values
        .map((value) => value[identifier])
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

    useEffect(() => {
        setShowSubValues(
            values.map((value) => ({ id: value[identifier], show: false }))
        )
    }, [valuesSum])

    useEffect(() => {}, [intervalReload])

    const getSubValues = (value: any): any[] | null => {
        if (!subValueField) return null
        for (const property of subValueField) {
            if (value && value.hasOwnProperty(property)) value = value[property]
            else return null
        }
        return value
    }

    function getRows(): any[] {
        if (!subValueField) return values

        const newRows: any[] = []

        values.forEach((value, index) => {
            newRows.push(value)
            const subValues = getSubValues(value)
            if (
                subValues &&
                Array.isArray(subValues) &&
                showSubValues[index]?.show
            )
                for (const subValue of subValues) {
                    newRows.push({
                        ...subValue,
                        isSubValue: true,
                    })
                }
        })

        return newRows
    }

    const handleChangeShowSubValues = (id: any, value: boolean) => {
        const newShowSubValues = [...showSubValues]
        const index = newShowSubValues.findIndex((v) => v.id === id)
        newShowSubValues[index].show = value
        setShowSubValues(newShowSubValues)
        if (handleClickRow && value) handleClickRow(id)
    }

    const TablePaper = withoutPaper ? Box : StyledPaper

    const newValues = getRows()

    const filtersLength = filters.filter((f) => !f.hideSelection).length
    const selectedWidth =
        filtersLength === 1
            ? 100
            : 100 / filters.filter((f) => !f.hideSelection).length - 1

    const handleCloseDropdownActions = () => {
        setDropdownActionsAnchorEl(null)
        selectedAction.current = null
    }

    const handleCloseIntervalMenu = () => {
        setIntervalReloadAnchorEl(null)
    }

    const handleChangeInterval = (v: number) => {
        setIntervalReload(v)
        clearTimeout(timeoutRef.current)
        setCurrentIntervalReload(0)
    }

    useEffect(() => {
        if (!intervalReload || !handleReload) {
            clearTimeout(timeoutRef.current)
            return
        }

        timeoutRef.current = setTimeout(() => {
            const value = currentIntervalReload + 1
            if (value === intervalReload) {
                handleReload()
                setCurrentIntervalReload(0)
            } else {
                setCurrentIntervalReload(value)
            }
        }, 1000)

        return () => {
            clearTimeout(timeoutRef.current)
        }
    }, [intervalReload, currentIntervalReload])

    return (
        <>
            <Menu
                anchorEl={intervalReloadAnchorEl}
                open={Boolean(intervalReloadAnchorEl)}
                onClose={handleCloseIntervalMenu}
            >
                <MenuItem
                    onClick={() => {
                        handleReload && handleReload()
                        handleCloseIntervalMenu()
                    }}
                >
                    Recarregar agora
                </MenuItem>
                {[5, 15, 30, 60].map((v) => (
                    <MenuItem
                        onClick={() => {
                            handleChangeInterval(v)
                            handleCloseIntervalMenu()
                        }}
                    >
                        Recarregar a cada {v} segundos
                    </MenuItem>
                ))}
                {intervalReload > 0 && (
                    <MenuItem
                        onClick={() => {
                            handleChangeInterval(0)
                            handleCloseIntervalMenu()
                        }}
                    >
                        Parar de carregar
                    </MenuItem>
                )}
            </Menu>
            <Menu
                anchorEl={dropdownActionsAnchorEl}
                open={Boolean(dropdownActionsAnchorEl)}
                onClose={handleCloseDropdownActions}
            >
                {selectedAction.current?.dropdownActions
                    ?.filter((action) => !action.hide)
                    .map((action) => (
                        <MenuItem
                            sx={{
                                width: { xs: '100%', md: '200px' },
                            }}
                            onClick={() => {
                                if (handleExecuteAction)
                                    handleExecuteAction(action.name)
                                handleCloseDropdownActions()
                            }}
                        >
                            {action.name}
                        </MenuItem>
                    ))}
            </Menu>
            <TablePaper sx={{ ...sx }}>
                {/* {title && <TitleHeader content={title} />} */}

                {!hideSearch && (
                    <Stack
                        width={'100%'}
                        direction={'row'}
                        alignItems={'center'}
                        mb={!hideFilters && filters.length ? '15px' : '5px'}
                    >
                        <StringField
                            label="Buscar"
                            maxLength={100}
                            value={search}
                            setValue={(v) => {
                                handleChangeSearch(v)
                            }}
                            sx={{
                                width: '100%',
                            }}
                            inputSx={{
                                borderRadius: handleReload ? '0px' : '3px',
                            }}
                            size="small"
                        />
                        {handleReload && (
                            <Stack sx={{ height: '40px' }}>
                                <Button
                                    variant="outlined"
                                    disableElevation
                                    size="small"
                                    color="inherit"
                                    sx={{
                                        borderLeft: '0',
                                        borderBottom: '0',
                                        borderRadius: '0px',
                                        color: BORDER_COLOR,
                                        height: '100%',
                                    }}
                                    onClick={(e) =>
                                        setIntervalReloadAnchorEl(
                                            e.currentTarget
                                        )
                                    }
                                >
                                    <ReplayIcon />
                                </Button>
                                <Box
                                    width="100%"
                                    height={'10px'}
                                    sx={{
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderLeft: '0',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: `${
                                                ((intervalReload -
                                                    currentIntervalReload) /
                                                    intervalReload) *
                                                100
                                            }%`,
                                            height: '100%',
                                            backgroundColor: 'primary.main',
                                        }}
                                    ></Box>
                                </Box>
                            </Stack>
                        )}
                    </Stack>
                )}

                {!hideFilters && (
                    <>
                        <Stack
                            flexDirection={'row'}
                            sx={{ width: '100%' }}
                            justifyContent={'space-between'}
                            flexWrap={'wrap'}
                        >
                            {filters
                                .filter((f) => !f.hideSelection)
                                .map((filter) => {
                                    if (filter.autoComplete) {
                                        return (
                                            <Autocomplete
                                                options={filter.values}
                                                getOptionLabel={(value) =>
                                                    value[filter.labelField]
                                                }
                                                sx={{
                                                    width: {
                                                        xs: '100%',
                                                        md: `${selectedWidth}%`,
                                                    },
                                                    mb: { xs: '15px', md: '0' },
                                                }}
                                                value={null}
                                                onChange={(e, value) => {
                                                    const foundSelectedValue =
                                                        filter.selectedValues.find(
                                                            (v) =>
                                                                v[
                                                                    filter
                                                                        .valueField
                                                                ] ===
                                                                value[
                                                                    filter
                                                                        .valueField
                                                                ]
                                                        )

                                                    if (
                                                        value &&
                                                        !foundSelectedValue
                                                    )
                                                        handleChangeSelectedFilter(
                                                            {
                                                                name: filter.name,
                                                                value: value,
                                                                action: 'add',
                                                            }
                                                        )
                                                }}
                                                size="small"
                                                blurOnSelect={true}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        onChange={(e) => {
                                                            filter.autoComplete?.cb(
                                                                e.target.value
                                                            )
                                                        }}
                                                        label={filter.name}
                                                        sx={{ width: '100%' }}
                                                        onFocus={() =>
                                                            filter.autoComplete?.cb(
                                                                ''
                                                            )
                                                        }
                                                    />
                                                )}
                                            />
                                        )
                                    }

                                    return (
                                        <FormControl
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    md: `${selectedWidth}%`,
                                                },
                                                mb: { xs: '15px', md: '0' },
                                            }}
                                            size="small"
                                        >
                                            <InputLabel>
                                                {filter.name}
                                            </InputLabel>
                                            <Select
                                                label={filter.name}
                                                fullWidth
                                                onChange={(e) => {
                                                    const { value } = e.target
                                                    const foundValue =
                                                        filter.values.find(
                                                            (v) =>
                                                                v[
                                                                    filter
                                                                        .valueField
                                                                ] === value
                                                        )
                                                    const foundSelectedValue =
                                                        filter.selectedValues.find(
                                                            (v) =>
                                                                v[
                                                                    filter
                                                                        .valueField
                                                                ] === value
                                                        )

                                                    if (
                                                        foundValue &&
                                                        !foundSelectedValue
                                                    )
                                                        handleChangeSelectedFilter(
                                                            {
                                                                name: filter.name,
                                                                value: foundValue,
                                                                action: 'add',
                                                            }
                                                        )
                                                }}
                                                value={''}
                                            >
                                                {filter.values.map((value) => (
                                                    <MenuItem
                                                        value={
                                                            value[
                                                                filter
                                                                    .valueField
                                                            ]
                                                        }
                                                    >
                                                        {
                                                            value[
                                                                filter
                                                                    .labelField
                                                            ]
                                                        }
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )
                                })}
                        </Stack>
                        <Stack direction={'row'} flexWrap={'wrap'}>
                            {filters.map((f) =>
                                f.selectedValues.map((value, i) => (
                                    <FilterBox
                                        name={f.name}
                                        value={value[f.labelField]}
                                        handleRemove={() => {
                                            handleChangeSelectedFilter({
                                                name: f.name,
                                                value: value[f.valueField],
                                                action: 'remove',
                                            })
                                        }}
                                    />
                                ))
                            )}
                        </Stack>
                    </>
                )}
                {!newValues.length ? (
                    <NoRowsDataGrid loading={isLoading} sx={sx} />
                ) : (
                    <>
                        <StyledDataGrid
                            rows={newValues}
                            loading={isLoading}
                            columns={columns
                                .filter((c) => {
                                    if (isXs && c.hideXs) return false
                                    if (isSm && c.hideSm) return false
                                    return true
                                })
                                .map((c, i, a) => ({
                                    field: i.toString(),
                                    valueGetter: (params) => {
                                        let value = params.row
                                        for (const property of c.property) {
                                            if (
                                                value &&
                                                value.hasOwnProperty(property)
                                            )
                                                value = value[property]
                                            else return ''
                                        }

                                        return c.parser
                                            ? c.parser(value)
                                            : value
                                    },
                                    headerName: c.name,
                                    flex: c.flex ?? 1,
                                    cellClassName: 'cell-border',

                                    renderCell: (params) => {
                                        return (
                                            <Stack
                                                justifyContent={'space-between'}
                                                direction={'row'}
                                                alignItems={'center'}
                                                width={'100%'}
                                            >
                                                {c.customRender ? (
                                                    c.customRender(
                                                        params.row,
                                                        params.value
                                                    )
                                                ) : (
                                                    <Tooltip
                                                        title={params.value}
                                                    >
                                                        <Typography
                                                            noWrap
                                                            fontSize={
                                                                '0.875rem'
                                                            }
                                                        >
                                                            {params.value}
                                                        </Typography>
                                                    </Tooltip>
                                                )}
                                                {!params.row.isSubValue &&
                                                subValueField &&
                                                i === 0 ? (
                                                    <>
                                                        {!showSubValues.find(
                                                            (s) =>
                                                                s.id ===
                                                                params.row[
                                                                    identifier
                                                                ]
                                                        )?.show ? (
                                                            <Tooltip title="Mostrar mais">
                                                                <KeyboardArrowDownIcon
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() =>
                                                                        handleChangeShowSubValues(
                                                                            params
                                                                                .row[
                                                                                identifier
                                                                            ],
                                                                            true
                                                                        )
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title="Mostrar menos">
                                                                <KeyboardArrowUpIcon
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() =>
                                                                        handleChangeShowSubValues(
                                                                            params
                                                                                .row[
                                                                                identifier
                                                                            ],
                                                                            false
                                                                        )
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                {(!params.row.isSubValue ||
                                                    isRecursive) &&
                                                handleClickDetails &&
                                                i === a.length - 1 ? (
                                                    <Tooltip title="Detalhes">
                                                        <SubdirectoryArrowRightIcon
                                                            sx={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                handleClickDetails(
                                                                    params.row[
                                                                        identifier
                                                                    ]
                                                                )
                                                            }}
                                                        />
                                                    </Tooltip>
                                                ) : (
                                                    <></>
                                                )}
                                            </Stack>
                                        )
                                    },
                                }))}
                            getRowId={(row) => {
                                if (
                                    row.isSubValue &&
                                    !isRecursive &&
                                    subValueIndentifier
                                ) {
                                    return `sub-${row[subValueIndentifier]}`
                                }
                                return row[identifier]
                            }}
                            checkboxSelection={!isXs && !withoutSelection}
                            disableRowSelectionOnClick={
                                !isXs || withoutSelection
                            }
                            isRowSelectable={(params) => {
                                if (withoutSelection) return false
                                return !params.row.isSubValue
                            }}
                            rowCount={
                                pagination
                                    ? pagination?.rowsCount
                                        ? pagination.rowsCount
                                        : Number.MAX_VALUE
                                    : undefined
                            }
                            sx={{
                                mt: '15px',
                                '.MuiTablePagination-displayedRows': {
                                    display: !pagination?.rowsCount
                                        ? 'none'
                                        : 'block',
                                },
                                '.MuiDataGrid-footerContainer': {
                                    display: hidePagination ? 'none' : 'block',
                                },
                                '& .MuiDataGrid-columnHeaders': {
                                    borderColor: BORDER_COLOR,
                                },
                                '& .MuiDataGrid-cell--textCenter': {
                                    borderColor: BORDER_COLOR,
                                },
                                borderColor: BORDER_COLOR,
                                ...sx,
                            }}
                            paginationMode={pagination ? 'server' : 'client'}
                            paginationModel={
                                pagination
                                    ? {
                                          page: pagination.page - 1,
                                          pageSize: pagination.rows,
                                      }
                                    : undefined
                            }
                            onPaginationModelChange={
                                pagination
                                    ? (paginationModel) => {
                                          pagination.handleChangePagination(
                                              paginationModel.page + 1,
                                              paginationModel.pageSize
                                          )
                                      }
                                    : undefined
                            }
                            rowSelectionModel={selectedValues}
                            keepNonExistentRowsSelected
                            onRowSelectionModelChange={(
                                newRowSelectionModel
                            ) => {
                                /* if (!isRecursive) { */
                                handleChangeSelectedValues(
                                    newRowSelectionModel as number[]
                                )
                                /* return;
              } */

                                /* const newSelectedValues: any[] = [];

              if (!subValueField) return;

              for (const id of newRowSelectionModel) {
                const value = newValues.find((v) => v[identifier] === id);
                const isSubValue = newValues.find(
                  (v) => v[identifier] === id
                )?.isSubValue;

                if (!isSubValue) {
                  const checked = !selectedValues.find(
                    (v) => v[identifier] === id
                  );
                  if (checked) {
                    const subValues = getSubValues(value);
                    if (subValues) newSelectedValues.push(...subValues);
                    newSelectedValues.push(value);
                  }
                  newSelectedValues.push(value);
                } else {
                  const parent = newValues.find((v) =>
                    getSubValues(v)?.find((s: any) => s[identifier] === id)
                  );
                  if (!parent) continue;

                  const parentWasUnchecked =
                    selectedValues.find(
                      (v) => v[identifier] === parent[identifier]
                    ) &&
                    !newRowSelectionModel.find(
                      (id) => id === parent[identifier]
                    );

                  if (!parentWasUnchecked) {
                    if (parent) newSelectedValues.push(parent);
                    newSelectedValues.push(value);
                  }
                }
              }

              const uniqueValues = newSelectedValues.filter(
                (v, i, a) =>
                  a.findIndex((v2) => v2[identifier] === v[identifier]) === i
              );
              handleChangeSelectedValues(uniqueValues); */
                            }}
                            getRowClassName={(params) => {
                                if (params.row.isSubValue && isRecursive)
                                    return 'recursive'
                                if (params.row.isSubValue) return 'subrow'
                                return 'row'
                            }}
                        />
                    </>
                )}
                <Stack
                    direction={'row'}
                    justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                >
                    {actions ? (
                        actions
                            .filter((action) => !action.hide)
                            .map((action) => (
                                <Button
                                    variant="contained"
                                    color={action.color}
                                    disableElevation
                                    sx={{
                                        ml: { xs: '0px', md: '10px' },
                                        mt: { xs: '10px', md: '20px' },
                                        textTransform: 'none',
                                        width: { xs: '100%', md: '200px' },
                                        p: '10px',
                                    }}
                                    onClick={(event) => {
                                        if (action.dropdownActions) {
                                            selectedAction.current = action
                                            setDropdownActionsAnchorEl(
                                                event.currentTarget
                                            )
                                        } else if (handleExecuteAction) {
                                            handleExecuteAction(action.name)
                                        }
                                    }}
                                    endIcon={
                                        action.dropdownActions ? (
                                            <KeyboardArrowDownIcon
                                                sx={{
                                                    position: 'absolute',
                                                    top: '30%',
                                                    right: '12px',
                                                    transform: 'scale(1.2)',
                                                }}
                                            />
                                        ) : undefined
                                    }
                                >
                                    {action.name}
                                </Button>
                            ))
                    ) : (
                        <></>
                    )}
                </Stack>
            </TablePaper>
        </>
    )
}

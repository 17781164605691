export function maskCurrency(
    value: string | number,
    digits: number = 2,
    locale = 'es-Py'
): string {
    if (typeof value === 'number')
        return new Intl.NumberFormat(locale, {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits,
        }).format(value)

    const numberValue = Number(value)

    if (isNaN(numberValue)) return '0,00'

    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    }).format(numberValue)
}

export function currencyToNumber(value: string, digits: number = 2): number {
    value = value.replace(/\D/g, '')
    const regex = new RegExp(`(\\d)(\\d{${digits}})$`)
    value = value.replace(regex, `$1.$2`)
    return Number(value)
}

export function removeExcessSpaces(value: string): string {
    return value.trim().replace(/\s\s+/g, ' ')
}

export function convertFileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (e) => {
            if (
                e.target &&
                e.target.result &&
                typeof e.target.result === 'string'
            )
                resolve(e.target.result)
            else reject()
        }
        reader.readAsDataURL(file)
    })
}

export function convertBase64ToFile(base64: string): Promise<File> {
    return new Promise<File>((resolve, reject) => {
        const arr = base64.split(',')

        const mime = arr[0].match(/:(.*?);/)?.pop()
        if (!mime) {
            reject()
            return
        }

        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }

        const file = new File([u8arr], 'imagem_camera.png', { type: mime })
        resolve(file)
    })
}

export function getDateInDDMMYYYYFormat(date: Date) {
    const dd = String(date.getDate()).padStart(2, '0')
    const mm = String(date.getMonth() + 1).padStart(2, '0')
    const yyyy = date.getFullYear()

    return `${dd}/${mm}/${yyyy}`
}

export function getDateInIsoFormat(date: Date, getHours?: boolean) {
    const dd = String(date.getDate()).padStart(2, '0')
    const mm = String(date.getMonth() + 1).padStart(2, '0')
    const yyyy = date.getFullYear()

    if (getHours) {
        const hh = String(date.getHours()).padStart(2, '0')
        const min = String(date.getMinutes()).padStart(2, '0')
        const ss = String(date.getSeconds()).padStart(2, '0')

        return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`
    }

    return `${yyyy}-${mm}-${dd}`
}

interface GetDateString {
    date?: string
    hour?: string
    dateObj?: Date
}

export function getDateString(data: GetDateString) {
    let date: Date | null = null

    if (data.date && data.hour) {
        date = new Date(`${data.date} ${data.hour}`)
    } else if (data.dateObj) {
        date = new Date(data.dateObj)
    }

    const dateString = date?.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    })
    const hourString = date?.toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    })

    return `${dateString} ${hourString}`
}

interface GetTimeAgo {
    date: string
}

export function getTimeAgo(data: GetTimeAgo) {
    const currentDate = new Date()
    const date = new Date(data.date)

    const seconds = Math.floor((currentDate.valueOf() - date.valueOf()) / 1000)

    let interval = seconds / 31536000

    if (interval > 1) {
        const floorInterval = Math.floor(interval)
        return `${floorInterval} ano${floorInterval !== 1 ? 's' : ''}`
    }
    interval = seconds / 2592000
    if (interval > 1) {
        const floorInterval = Math.floor(interval)
        return `${floorInterval} mes${floorInterval !== 1 ? 's' : ''}`
    }
    interval = seconds / 86400
    if (interval > 1) {
        const floorInterval = Math.floor(interval)
        return `${floorInterval} dia${floorInterval !== 1 ? 's' : ''}`
    }
    interval = seconds / 3600
    if (interval > 1) {
        const floorInterval = Math.floor(interval)
        return `${floorInterval} hora${floorInterval !== 1 ? 's' : ''}`
    }
    interval = seconds / 60
    if (interval > 1) {
        const floorInterval = Math.floor(interval)
        return `${floorInterval} minuto${floorInterval !== 1 ? 's' : ''}`
    }
    const floorInterval = Math.floor(interval)
    return `${floorInterval} segundo${floorInterval !== 1 ? 's' : ''}`
}

export interface ListReturn<T> {
    data: T[]
    count: number
}

export function validateRuc(ruc: string) {
    return /^\d{6,8}-\d$/.test(ruc) || /^\d{6,8}$/.test(ruc)
}

export function validateEmail(email: string): boolean {
    return /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(email)
}

export function normalizeString(v: string) {
    return v.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

import axios from 'axios'
import { doLogout } from './auth'

export interface PaginatedResponse<I> {
    data: I[]
    count: number
}

interface RequestConfig {
    useAuth?: boolean
    multipart?: boolean
    useRecaptcha?: boolean
}

const defaultRequestConfig: RequestConfig = {
    useAuth: true,
    multipart: false,
    useRecaptcha: false,
}

function getInstance(requestConfig?: RequestConfig) {
    const config = requestConfig
        ? { ...defaultRequestConfig, ...requestConfig }
        : { ...defaultRequestConfig }

    const baseURL = process.env.REACT_APP_API_ENDPOINT
    const token = localStorage.getItem('token')

    if (config?.useAuth && !token) {
        throw new Error('User not authenticated!')
    }

    const headers = {
        'Content-Type': !config.multipart
            ? 'application/json'
            : 'requestConfig/form-data',
        Authorization: config?.useAuth ? `Bearer ${token}` : undefined,
    }

    const instance = axios.create({
        baseURL,
        headers,
    })

    instance.interceptors.request.use(
        async (requestConfig) => {
            if (config.useRecaptcha) {
                const recaptchaToken = await execupteRecaptcha()

                requestConfig.headers['recaptcha-token'] = recaptchaToken
            }

            return requestConfig
        },
        (err) => Promise.reject(err)
    )

    instance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (
                error.response.data.data.message ===
                'Unauthorized, token expired'
            ) {
                doLogout()
                window.location.href = '/entrar?expired=true'
                return
            }

            throw error
        }
    )

    return instance
}

export async function get(endpoint: string, requestConfig?: RequestConfig) {
    try {
        const axios = getInstance(requestConfig)
        const res = await axios.get(endpoint)
        return res
    } catch (error: any) {
        throw error.response
    }
}

export async function post(
    endpoint: string,
    body: any,
    requestConfig?: RequestConfig
) {
    try {
        const axios = getInstance(requestConfig)
        const res = await axios.post(endpoint, body)
        return res
    } catch (error: any) {
        throw error.response
    }
}

export async function put(
    endpoint: string,
    body: any,
    requestConfig?: RequestConfig
) {
    try {
        const axios = getInstance(requestConfig)
        const res = await axios.put(endpoint, body)
        return res
    } catch (error: any) {
        throw error.response
    }
}

export async function del(endpoint: string, requestConfig?: RequestConfig) {
    try {
        const axios = getInstance(requestConfig)
        const res = await axios.delete(endpoint)
        return res
    } catch (error: any) {
        throw error.response
    }
}

export function execupteRecaptcha(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const key = process.env.REACT_APP_RECAPTCHA_SITE_KEY

        if (!window.grecaptcha || !key) {
            reject()
            return
        }

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(key, { action: 'submit' }).then(
                (token) => resolve(token),
                (err) => reject(err)
            )
        })
    })
}

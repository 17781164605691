import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Breadcrumbs, IconButton, Link } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useRef, useState } from 'react'
import { doLogout } from '../../services/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { Navigation, navMenu } from '../LeftPanel/nav'

interface Props {
    drawerWidth: number
    headerHeight: number
    setShowMobile(value: boolean): void
    hideSideBar: boolean
}

const Header = ({
    drawerWidth,
    setShowMobile,
    headerHeight,
    hideSideBar,
}: Props) => {
    const appBarRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [nav, setNav] = useState<Navigation | null>(null)
    const [subnav, setSubNav] = useState<Navigation | null>(null)

    const logout = () => {
        doLogout()
        navigate('/login')
    }

    useEffect(() => {
        navMenu.forEach((nav) => {
            const subnav = nav.subitems?.find(
                (subnav) => subnav.link === pathname.replace(/\/\d+$/, '')
            )
            if (subnav) {
                setNav(nav)
                setSubNav(subnav)
            }
        })
    }, [pathname])

    return (
        <AppBar
            position="sticky"
            sx={{
                width: {
                    xs: '100%',
                    lg: hideSideBar ? '100%' : `calc(100% - ${drawerWidth}px)`,
                },
                ml: { xs: '0', lg: hideSideBar ? '0' : `${drawerWidth}px` },
                background: '#FFF',
            }}
            ref={appBarRef}
            elevation={0}
            className="header"
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    height: `${headerHeight}px`,
                    alignItems: 'center',
                    pr: '30px',
                    justifyContent: 'space-between',
                }}
            >
                <Box display={'flex'} alignItems={'center'}>
                    <IconButton
                        edge="start"
                        onClick={() => setShowMobile(true)}
                        sx={{
                            ml: '10px',
                            display: {
                                xs: 'block',
                                lg: hideSideBar ? 'block' : 'none',
                            },
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Breadcrumbs sx={{ ml: '30px' }}>
                        <Link
                            underline="hover"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            color="inherit"
                            href={nav?.link}
                        >
                            {/* <Box mr={0.5}>{nav?.icon}</Box> */}
                            {nav?.text}
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            href={nav?.link}
                        >
                            {/* <Box mr={0.5}>{subnav?.icon}</Box> */}
                            {subnav?.text}
                        </Link>
                    </Breadcrumbs>
                </Box>
                {/* <Box
                    component="img"
                    src="/logo.jpg"
                    sx={{ padding: '20px', height: '90px' }}
                /> */}
                <Box
                    sx={{
                        color: 'black',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        component="span"
                        sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: '#DC143C',
                            ml: '5px',
                        }}
                        onClick={logout}
                    >
                        Salir
                    </Box>
                </Box>
            </Box>
        </AppBar>
    )
}

export default Header

import { Navigate, Route, Routes } from 'react-router-dom'
import { CompanyHome } from './Home'
import { RegisterCompany } from './Register'

export const Company = () => {
    return (
        <Routes>
            <Route path="" element={<CompanyHome />} />
            <Route path="new" element={<RegisterCompany isEditing={false} />} />
            <Route
                path="edit/:id"
                element={<RegisterCompany isEditing={true} />}
            />
            <Route path="*" element={<Navigate to="/system/company" />} />
        </Routes>
    )
}

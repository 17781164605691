import { useEffect, useState } from 'react'
import { StyledPaper } from '../../../components/StyledPaper'
import {
    Company,
    findCompanyById,
    registerCompany,
    updateCompany,
} from '../../../services/company'
import { Stack } from '@mui/system'
import { StringField } from '../../../components/StringField'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material'
import { useLoading } from '../../../context/loading_context'
import { useSnack } from '../../../context/snack_context'
import { useNavigate, useParams } from 'react-router-dom'
import { ActionsFooter } from '../../../components/ActionsFooter'
import { CurrencyField } from '../../../components/CurrencyField'

interface Props {
    isEditing: boolean
}

export const RegisterCompany = ({ isEditing }: Props) => {
    const loading = useLoading()
    const snack = useSnack()
    const navigate = useNavigate()
    const [company, setCompany] = useState<Omit<Company, 'id'>>({
        name: '',
        isBlocked: false,
        type: 'local',
        keyActivated: false,
    })
    const { id } = useParams()

    useEffect(() => {
        handleInit()
    }, [])

    const handleInit = async () => {
        if (!isEditing) {
            setCompany({
                name: '',
                isBlocked: false,
                type: 'local',
                keyActivated: false,
            })
            return
        }

        if (!id) {
            navigate(-1)
            snack.connectionFail()
            return
        }

        loading.show()
        try {
            const res = await findCompanyById(Number(id))
            setCompany(res)
        } catch (error) {
            navigate(-1)
            snack.connectionFail()
        }
        loading.hide()
    }

    const handleConfirm = async () => {
        loading.show()
        try {
            const body = {
                ...company,
                paymentDate: company.paymentDate || undefined,
                paymentValue: Number(company.paymentValue) || undefined,
                key: company.key || undefined,
                dbName: company.dbName || undefined,
            }
            if (!isEditing) await registerCompany(body)
            else await updateCompany(Number(id), body)
            snack.success(
                `Empresa ${!isEditing ? 'criada' : 'atualizada'} com sucesso!`
            )
            navigate('/system/company')
        } catch (error) {
            snack.connectionFail()
        }
        loading.hide()
    }

    return (
        <>
            <StyledPaper>
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    width="100%"
                    justifyContent={'space-between'}
                >
                    <StringField
                        label="Nome"
                        maxLength={100}
                        value={company.name}
                        setValue={(v) =>
                            setCompany((prev) => ({
                                ...prev,
                                name: v,
                            }))
                        }
                        sx={{ width: { xs: '100%', md: '49%' } }}
                    />
                    <FormControl
                        sx={{
                            width: { xs: '100%', md: '49%' },
                            mt: { xs: '15px', md: '0' },
                        }}
                    >
                        <InputLabel>Tipo</InputLabel>
                        <Select
                            label="Tipo"
                            fullWidth
                            value={company.type}
                            onChange={(e) => {
                                const { value } = e.target
                                setCompany((prev) => ({
                                    ...prev,
                                    type: value as 'local' | 'nuvem',
                                }))
                            }}
                        >
                            <MenuItem value={'local'}>Local</MenuItem>
                            <MenuItem value={'nuvem'}>Nuvem</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    width="100%"
                    justifyContent={'space-between'}
                    mt="15px"
                >
                    <StringField
                        label="Dia do pagamento"
                        maxLength={100}
                        value={company.paymentDate ?? ''}
                        setValue={(v) =>
                            setCompany((prev) => ({
                                ...prev,
                                paymentDate: v,
                            }))
                        }
                        sx={{ width: { xs: '100%', md: '49%' } }}
                    />
                    <CurrencyField
                        label="Valor mensalidade"
                        value={Number(company.paymentValue)}
                        setValue={(v) =>
                            setCompany((prev) => ({
                                ...prev,
                                paymentValue: v,
                            }))
                        }
                        sx={{
                            width: { xs: '100%', md: '49%' },
                            mt: { xs: '15px', md: '0' },
                        }}
                    />
                </Stack>
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    width="100%"
                    justifyContent={'space-between'}
                    mt="15px"
                >
                    <StringField
                        label="Chave de ativação"
                        maxLength={100}
                        value={company.key ?? ''}
                        setValue={(v) =>
                            setCompany((prev) => ({
                                ...prev,
                                key: v,
                            }))
                        }
                        sx={{ width: { xs: '100%', md: '49%' } }}
                    />
                    <StringField
                        label="Nome BD"
                        maxLength={100}
                        value={company.dbName ?? ''}
                        setValue={(v) =>
                            setCompany((prev) => ({
                                ...prev,
                                dbName: v,
                            }))
                        }
                        sx={{
                            width: { xs: '100%', md: '49%' },
                            mt: { xs: '15px', md: '0' },
                        }}
                    />
                </Stack>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={company.isBlocked}
                            onChange={(e) =>
                                setCompany((prev) => ({
                                    ...prev,
                                    isBlocked: e.target.checked,
                                }))
                            }
                        />
                    }
                    label="Bloqueado"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={company.keyActivated}
                            onChange={(e) =>
                                setCompany((prev) => ({
                                    ...prev,
                                    keyActivated: e.target.checked,
                                }))
                            }
                        />
                    }
                    label="Chave ativa"
                />
            </StyledPaper>
            <ActionsFooter
                cancelLabel="Voltar"
                okLabel="Confirmar"
                cancelClick={() => navigate(-1)}
                okClick={handleConfirm}
            />
        </>
    )
}

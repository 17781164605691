import AddIcon from '@mui/icons-material/Add'
import ListIcon from '@mui/icons-material/List'
import StorefrontIcon from '@mui/icons-material/Storefront'

export interface Navigation {
    text: string
    validate(): boolean
    icon?: JSX.Element
    link?: string
    subitems?: Navigation[]
    reducedText?: string
    description?: string
    hide?: boolean
}

export const navMenu: Navigation[] = [
    {
        text: 'Empresa',
        icon: <StorefrontIcon />,
        validate: () => true,
        subitems: [
            {
                text: 'Listar empresas',
                reducedText: 'Listar',
                validate: () => true,
                link: '/system/company',
                icon: <ListIcon />,
            },
            {
                text: 'Nova empresa',
                reducedText: 'Nova',
                validate: () => true,
                link: '/system/company/new',
                icon: <AddIcon />,
            },
            {
                text: 'Editar empresa',
                reducedText: 'Editar',
                validate: () => true,
                link: '/system/company/edit',
                hide: true,
            },
        ],
    },
]

function getFlatMenu(): Navigation[] {
    const newNav: Navigation[] = []
    for (const nav of navMenu) {
        newNav.push(nav)
        if (nav.subitems)
            for (const subNav of nav.subitems) {
                newNav.push(subNav)
            }
    }

    return newNav
}

export const flatNavMenu = getFlatMenu()

import { Box, SxProps } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
    name: string
    value: string
    handleRemove(): void
    sx?: SxProps
}

export const FilterBox = ({ name, value, handleRemove, sx }: Props) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'rgb(58, 73, 231, 0.2)',
                color: '#000',
                fontSize: '14px',
                p: '7px 12px',
                borderRadius: '12px',
                mt: '10px',
                ml: '10px',
                ...sx,
            }}
        >
            <Box>
                {name}: {value}
            </Box>
            <CloseIcon
                sx={{
                    color: '#000',
                    transform: 'scale(0.8)',
                    ml: '5px',
                    cursor: 'pointer',
                }}
                onClick={handleRemove}
            />
        </Box>
    )
}

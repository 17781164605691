import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import LeftPanel from '../LeftPanel'
import { flatNavMenu } from '../LeftPanel/nav'

interface Props {
    children: React.ReactNode
}

const Layout = ({ children }: Props) => {
    const [drawerWidth] = useState(240)
    const [headerHeight] = useState(70)
    const [hideSideBar, setHideSideBar] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        setHideSideBar(
            ['/system/sale/new', '/system/sale/budget/new'].includes(
                location.pathname
            )
        )
    }, [location.pathname])

    useEffect(() => {
        const nav = flatNavMenu.find(
            (n) => n.link === location.pathname.replace(/\/\d+$/, '')
        )
        if (!nav?.validate()) {
            navigate('/system')
        }
    }, [location.pathname])

    return (
        <>
            <LeftPanel
                drawerWidth={drawerWidth}
                headerHeight={headerHeight}
                hideSideBar={hideSideBar}
            />
            <Box
                sx={{
                    width: {
                        /* xs: '100%' */ lg: hideSideBar
                            ? '100%'
                            : `calc(100% - ${drawerWidth}px)`,
                    },
                    ml: {
                        /* xs: '0' */ lg: hideSideBar
                            ? '0'
                            : `${drawerWidth}px`,
                    },
                    minHeight: `calc(100vh - ${headerHeight}px)`,
                    background: '#E5E4E2',
                    p: { xs: '5px', md: '30px 40px' },
                }}
            >
                {children}
            </Box>
        </>
    )
}

export default Layout

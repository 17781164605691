import { get, PaginatedResponse, post, put } from './generic'

export interface Company {
    id: number
    name: string
    isBlocked: boolean
    type: 'local' | 'nuvem'
    key?: string
    keyActivated?: boolean
    dbName?: string
    jwt?: string
    paymentDate?: string
    paymentValue?: number
}

export async function listCompanies(
    search: string,
    page: number,
    rows: number
): Promise<PaginatedResponse<Company>> {
    const res = await get(
        `admin/company?search=${search}&page=${page}&rows=${rows}`
    )
    return res.data
}

export async function findCompanyById(id: number): Promise<Company> {
    const res = await get(`admin/company/${id}`)
    return res.data
}

export async function registerCompany(data: Omit<Company, 'id'>) {
    await post('admin/company', data)
}

export async function updateCompany(id: number, data: Omit<Company, 'id'>) {
    await put('admin/company', { ...data, id })
}

export async function executeQuery(query: string) {
    await post('admin/execute-query', { query })
}

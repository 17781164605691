import { useEffect, useRef, useState } from 'react'
import { GenericTable } from '../../../components/GenericTable'
import { Company, executeQuery, listCompanies } from '../../../services/company'
import { useLoading } from '../../../context/loading_context'
import { useSnack } from '../../../context/snack_context'
import { maskCurrency } from '../../../utils/maks'
import { useNavigate } from 'react-router-dom'
import GenericModal from '../../../components/GenericModal'
import { StringField } from '../../../components/StringField'

interface CompanyTableFilters {
    page: number
    rows: number
    rowsCount: number
}

export const CompanyHome = () => {
    const loading = useLoading()
    const snack = useSnack()
    const navigate = useNavigate()
    const [companies, setCompanies] = useState<Company[]>([])
    const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([])
    const [filters, setFilters] = useState<CompanyTableFilters>({
        page: 1,
        rows: 10,
        rowsCount: 0,
    })
    const [search, setSearch] = useState('')
    const timeoutRef = useRef<NodeJS.Timeout>()
    const [openExecuteQueryModal, setOpenExecuteQueryModal] = useState(false)
    const [query, setQuery] = useState('')

    useEffect(() => {
        handleLoadCompanies(filters, search)
    }, [])

    const handleLoadCompanies = async (
        filters: CompanyTableFilters,
        search: string
    ) => {
        loading.logicShow()
        try {
            const res = await listCompanies(search, filters.page, filters.rows)
            setCompanies(res.data)
            setFilters((prev) => ({
                ...prev,
                rowsCount: res.count,
            }))
        } catch (error) {
            snack.connectionFail()
        }
        loading.logicHide()
    }

    const handleExecuteQuery = async () => {
        loading.show()
        try {
            await executeQuery(query)
            setQuery('')
            setOpenExecuteQueryModal(false)
        } catch (error) {
            snack.connectionFail()
        }
        loading.hide()
    }

    return (
        <>
            <GenericModal
                open={openExecuteQueryModal}
                handleClose={setOpenExecuteQueryModal}
                title="Executar query"
                textButton="Confirmar"
                disabledButton={!query}
                handleSubmit={handleExecuteQuery}
                fullWidth
            >
                <StringField
                    label="Query"
                    multiLineRows={4}
                    value={query}
                    setValue={setQuery}
                    sx={{ width: '100%', mt: '5px' }}
                />
            </GenericModal>
            <GenericTable
                title=""
                filters={[]}
                search={search}
                handleChangeSearch={(v) => {
                    setSearch(v)
                    if (v === search) return

                    clearTimeout(timeoutRef.current)
                    timeoutRef.current = setTimeout(() => {
                        handleLoadCompanies({ ...filters, page: 1 }, v)
                    }, 500)
                }}
                handleChangeSelectedFilter={() => {}}
                handleChangeSelectedValues={(values) => {
                    setSelectedCompanies(
                        values.map((value) => {
                            const foundValue = selectedCompanies.find(
                                (sv) => sv.id === value
                            )
                            if (foundValue) return foundValue
                            else
                                return companies.filter(
                                    (v) => v.id === value
                                )[0]
                        })
                    )
                }}
                selectedValues={selectedCompanies.map((p) => p.id as number)}
                identifier="id"
                values={companies}
                pagination={{
                    page: filters.page,
                    rows: filters.rows,
                    rowsCount: filters.rowsCount,
                    handleChangePagination: (page, rows) => {
                        if (
                            page > filters.page &&
                            companies.length % rows !== 0
                        )
                            return
                        handleLoadCompanies({ ...filters, page, rows }, search)
                        setFilters({ ...filters, page, rows })
                    },
                }}
                columns={[
                    {
                        name: 'Nome',
                        property: ['name'],
                    },
                    {
                        name: 'Tipo',
                        property: ['type'],
                    },
                    {
                        name: 'Nome BD',
                        property: ['dbName'],
                        parser: (v) => v || '---',
                    },
                    {
                        name: 'Dia pagamento',
                        property: ['paymentDate'],
                    },
                    {
                        name: 'Valor mensalidade',
                        property: ['paymentValue'],
                        parser: (v) => maskCurrency(v),
                    },
                    {
                        name: 'Bloquedo',
                        property: ['isBlocked'],
                        parser: (v) => (v ? 'Sim' : 'Não'),
                    },
                ]}
                actions={[
                    {
                        name: 'Ações',
                        color: 'error',
                        dropdownActions: [
                            { name: 'Editar', color: 'secondary' },
                            { name: 'Executar query', color: 'secondary' },
                        ],
                    },
                    { name: 'Novo', color: 'primary' },
                ]}
                handleExecuteAction={(action) => {
                    if (action === 'Editar' && selectedCompanies.length)
                        navigate(`edit/${selectedCompanies[0].id}`)
                    if (action === 'Executar query')
                        setOpenExecuteQueryModal(true)
                    if (action === 'Novo') navigate('new')
                }}
            />
        </>
    )
}

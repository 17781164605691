import { Paper, SxProps } from '@mui/material'
import React from 'react'

interface Props {
    children: React.ReactNode
    elevation?: number
    sx?: SxProps
}

export const StyledPaper = ({ children, elevation, sx }: Props) => {
    return (
        <Paper
            elevation={elevation !== undefined ? elevation : 1}
            sx={{
                width: '100%',
                p: '20px',
                /* borderRadius: '12px', */
                wordBreak: 'break-word',
                ...sx,
            }}
            className="styledPaper"
        >
            {children}
        </Paper>
    )
}

import { SxProps, TextField, Typography } from '@mui/material'
import React, { forwardRef, useEffect, useState } from 'react'
import { currencyToNumber, maskCurrency } from '../../utils/maks'

interface Props {
    value: number
    setValue(v: number): void
    label: string
    sx?: SxProps
    error?: string
    onClick?(): void
    size?: 'small' | 'medium'
    endAdornment?: JSX.Element
    maxValue?: number
    digits?: number
}

export const CurrencyField = forwardRef<HTMLInputElement, Props>(
    (
        {
            value,
            setValue,
            label,
            sx,
            error,
            onClick,
            size,
            endAdornment,
            maxValue,
            digits = 2,
        }: Props,
        ref
    ) => {
        const [localValue, setLocalValue] = useState('')

        useEffect(() => {
            setLocalValue(maskCurrency(value))
        }, [value])

        return (
            <TextField
                sx={{
                    ...sx,
                }}
                error={Boolean(error)}
                helperText={error}
                onClick={onClick}
                value={localValue}
                label={label}
                onChange={(e) => {
                    let newValue = e.target.value.replace(/\D/g, '')
                    let numberValue

                    const divider = 100 * Math.pow(10, digits - 2)
                    numberValue = Number(newValue) / divider

                    if (maxValue && numberValue > maxValue) return
                    if (value === numberValue) return
                    setValue(numberValue)
                }}
                inputRef={ref}
                InputProps={{
                    startAdornment: (
                        <Typography sx={{ mr: '5px', whiteSpace: 'nowrap' }}>
                            G$
                        </Typography>
                    ),
                    endAdornment: endAdornment,
                }}
                size={size}
            />
        )
    }
)
